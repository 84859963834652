export const form = {
	id:undefined,
	title:undefined,
	organizerId:undefined,
	isShow:false,
	activityObject:undefined,
	content:'',
	registrationStartTime:undefined,
	registrationEndTime:undefined,
	activityStartTime:undefined,
	activityEndTime:undefined,
	activityAddress:undefined,
	registrationNumMax:undefined,
	registrationCost:undefined,
	activityContact:undefined,
	activityTel:undefined,
	imgUrls:[]
}
export const rules = {
	title:[{required:true,message:'请输入标题',trigger:'blur'}],
	organizerId:[{required:true,message:'请选择',trigger:'change'}],
	isShow:[{required:true,message:'请选择',trigger:'change'}],
	activityObject:[{required:true,message:'请选择活动对象',trigger:'change'}],
	content:[{required:true,message:'请输入内容',trigger:'blur'}],
	registrationStartTime:[{required:true,message:'请选择',trigger:'change'}],
	registrationEndTime:[{required:true,message:'请选择',trigger:'change'}],
	activityStartTime:[{required:true,message:'请选择',trigger:'change'}],
	activityEndTime:[{required:true,message:'请选择',trigger:'change'}],
	activityAddress:[{required:true,message:'请输入活动地址',trigger:'blur'}],
	registrationNumMax:[{required:true,message:'请输入人数上限',trigger:'blur'}],
	registrationCost:[{required:true,message:'请输入报名费用',trigger:'blur'}],
	activityContact:[{required:true,message:'请输入活动联系人',trigger:'blur'}],
	activityTel:[{required:true,message:'请输入活动联系方式',trigger:'blur'}],
}
export const options = {
	isShow:[
		{ id:true, name:'显示' },
		{ id:false, name:'不显示' },
	],
	activityObject: [
		{ id:1, name:'全部' },
		{ id:2, name:'住户' },
		{ id:3, name:'业主' },
		{ id:4, name:'租户' },
		{ id:5, name:'管家' },
	],
	organizer:[]
}