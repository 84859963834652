<template>
  <div>
    <a-drawer
      title="活动详情"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="detailClose"
    >
      <div class="drawer-content">
        <div style="display: flex">
          <div><img v-for="item,index in detailData.imgList" :key='index' :src="$ImgUrl(item.url)" class="content-img"></div>
          <div>
            <div class="content-title">{{ detailData.title }}</div>
            <div style="margin-top: 6px;width: 500px">主办方：{{detailData.organizerName}}
              <a-tag style="float: right;width: 86px;height: 24px;text-align:center">
                {{detailData.status == 1?'报名未开始':2?'报名进行中':3?'活动未开始':4?'活动进行中':'活动已结束'}}
              </a-tag>
            </div>
          </div>
        </div>
        <a-divider></a-divider>
		    <a-descriptions class="content-des" layout="vertical">
          <a-descriptions-item label="创建时间">
            {{ detailData.createDate }}
          </a-descriptions-item>
          <a-descriptions-item label="是否显示在app上">
            {{ detailData.isShow ? "是" : "否" }}
          </a-descriptions-item>
          <a-descriptions-item label="活动对象">
            {{ detailData.activityObject | activityObject }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions style="padding: 0px 32px 0px 32px" layout="vertical" :column="1">
          <a-descriptions-item label="活动内容">
            {{ detailData.content }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="content-des" title="报名设置" layout="vertical">
          <a-descriptions-item label="报名开始时间">
            {{ detailData.registrationStartTime }}
          </a-descriptions-item>
          <a-descriptions-item label="报名结束时间">
            {{ detailData.registrationEndTime }}
          </a-descriptions-item>
          <a-descriptions-item label="报名人数上限">
            {{ detailData.registrationNumMax }}
          </a-descriptions-item>
          <a-descriptions-item label="活动地点">
            {{ detailData.activityAddress }}
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions class="content-des" title="活动设置" layout="vertical">
          <a-descriptions-item label="活动开始时间">
            {{ detailData.activityStartTime }}
          </a-descriptions-item>
          <a-descriptions-item label="活动结束时间">
            {{ detailData.activityEndTime }}
          </a-descriptions-item>
          <a-descriptions-item label="活动联系人">
            {{ detailData.activityContact }}
          </a-descriptions-item>
          <a-descriptions-item label="活动联系方式">
            {{ detailData.activityTel }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="content-des">
          <div class="des-title">报名记录</div>
          <a-divider></a-divider>
          <a-table
            :columns="detailColumns"
            :data-source="tableData"
            :pagination="pagination"
            :scroll="{ x: 600 }"
            @change="handleTableChange"
            :row-key="
              (record, index) => {
                return record.id;
              }
            "
          ></a-table>
        </div>
        
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="detailClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { activityInfo, registrationList } from "@/api/operation/activity";
import { detailColumns, detailpagination } from "./config";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		detailId: Number,
	},
	data() {
		return {
			tableData: [],
			detailData: {},
			detailColumns,
			pagination: detailpagination,
		};
	},
	mounted() {},
	filters: {
		activityObject(value) {
			switch (value) {
			case 1:
				return "全部";
				break;
			case 2:
				return "住户";
				break;
			case 3:
				return "业主";
				break;
			case 4:
				return "租户";
				break;
			case 5:
				return "管家";
				break;

			default:
				break;
			}
		},
	},
	methods: {
		detailClose() {
			this.$emit("detailClose");
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
	},
	watch: {
		detailId: {
			handler(id) {
				if (id !== null && id !== undefined) {
					Promise.all([
						activityInfo({ activityId: id }),
						registrationList({ activityId: id }),
					]).then((res) => {
						this.detailData = res[0].data;
						this.tableData = res[1].data.rows;
						this.pagination.total = res[1].data.total;
					});
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="less" scoped>
.content-title {
  color: #000000d9;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  padding: 12px 0px 12px 0px;
}
.content-img{
	width: 108px;
	height: 86px;
}
.content-des {
  padding: 32px
}
.des-title {
  margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
}
</style>
