export const formItem = [
	
	{
		type: 'select',
		label:'状态',
		prop:'status',
		option:[{id: null,name:'全部'},{ id:1,name:'报名未开始'},{ id:2,name:'报名进行中'},{ id:3,name:'活动未开始'},{ id:4,name:'活动进行中'},{ id:5,name:'活动已结束'}],
		placeholder:'请选择状态'
	},
	{
		type: 'time',
		label: '报名时间',
		prop:'registration',
		start: 'registrationStartTime',
		end:'registrationEndTime',
	},
	{
		type: 'time',
		label: '活动时间',
		prop:'activity',
		start: 'activityStartTime',
		end:'activityEndTime',
	},
	{
		type: 'input',
		label:'活动标题',
		prop:'title',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'主办方',
		prop:'organizerName',
		placeholder:'请输入'
	},
	// {
	// 	type: 'input',
	// 	label:'活动联系人',
	// 	prop:'activityContact',
	// 	placeholder:'请输入'
	// },
	
]
export const columns = [
	{
		title: "发布时间",
		dataIndex: "createDate",
		width: "10%",
	},
	{
		title: "图片",
		dataIndex: "imgpic",
		width: "5%",
		scopedSlots: { customRender: "imgpic"}
	},
	{
		title: "活动状态",
		dataIndex: "status",
		width: "5%",
		customRender: function (status) {
			switch (status) {
			case 1:
				return '报名未开始'
			case 2:
				return '报名进行中'
			case 3:
				return '活动未开始'
			case 4:
				return '活动进行中'
			case 5:
				return '活动已结束'
			}
		}
	},
	{
		title: "活动标题",
		dataIndex: "title",
		width: "8%",
	},
	{
		title: "活动地点",
		dataIndex: "activityAddress",
		width: "8%",
	},
	{
		title: "是否显示在app",
		dataIndex: "isShow",
		width: "5%",
		customRender: function (isShow) {
			switch (isShow) {
			case true:
				return '显示'
			case false:
				return '不显示'
			}
		}
	},
	{
		title: "主办方",
		dataIndex: "organizerName",
		width: "6%",
	},
	{
		title: "活动内容",
		dataIndex: "content",
		width: "10%",
		scopedSlots: { customRender: "content" },
	},
	{
		title: "报名人数",
		dataIndex: "registrationNum",
		width: "5%",
	},
	{
		title: "报名时间",
		width: "14%",
		customRender: function (data) {
			return (data.registrationStartTime + '——' + data.registrationEndTime)
		}
	},
	{
		title: "活动时间",
		width: "14%",
		customRender: function (data) {
			return (data.activityStartTime + '——' + data.activityEndTime)
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const detailColumns = [
	{
		title: "报名人",
		dataIndex: "name",
		width: "22%",
	},
	{
		title: "报名联系方式",
		dataIndex: "tel",
		width: "32%",
	},
	{
		title: "报名时间",
		dataIndex: "createDate",
		width: "32%",
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
export const detailpagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}