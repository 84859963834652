<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item prop="title" label="标题">
                <a-input
                  v-model="form.title"
                  placeholder="请输入标题"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="organizerId" label="主办方">
                <a-select
                  v-model="form.organizerId"
                  placeholder="请选择"
                  style="width: 80%"
                >
                  <a-select-option
                    v-for="item in options.organizer"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.unit }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="isShow" label="是否显示">
                <a-switch
                  checked-children="开"
                  un-checked-children="关"
                  v-model="form.isShow"
                  style="margin-left: 20px"
                >
                </a-switch>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="activityObject" label="活动对象">
                <a-select
                  v-model="form.activityObject"
                  placeholder="请选择"
                  style="width: 80%"
                >
                  <a-select-option
                    v-for="item in options.activityObject"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item prop="content" label="内容">
                <a-textarea
                  v-model="form.content"
                  placeholder="请输入内容"
                  style="width: 80%"
                  :autosize="{minRows: 10}"
                ></a-textarea>
                <div>
                    已输入 {{form.content.length}} / 1024 字
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                prop="registrationStartTime"
                label="报名开始时间"
              >
                <a-date-picker
                  v-model="form.registrationStartTime"
                  value-format="YYYY-MM-DD HH:mm:ss"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item
                prop="registrationEndTime"
                label="报名结束时间"
              >
                <a-date-picker
                  :disabledDate="disabledDate"
                  v-model="form.registrationEndTime"
                  value-format="YYYY-MM-DD HH:mm:ss"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="activityStartTime" label="活动开始时间">
                <a-date-picker
                  v-model="form.activityStartTime"
                  value-format="YYYY-MM-DD HH:mm:ss"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="activityEndTime" label="活动结束时间">
                <a-date-picker
                  :disabledDate="disabledDate2"
                  v-model="form.activityEndTime"
                  value-format="YYYY-MM-DD HH:mm:ss"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="registrationNumMax" label="报名人数上限">
                <a-input-number
                  v-model="form.registrationNumMax"
                  placeholder="请输入人数"
                  :min="0"
                  style="width: 30%"
                ></a-input-number>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="activityAddress" label="活动地址">
                <a-input
                  v-model="form.activityAddress"
                  placeholder="请输入活动地址"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="registrationCost" label="报名费用">
                <a-input-number
                  v-model="form.registrationCost"
                  placeholder="请输入费用"
                  :min="0"
                  style="width: 30%"
                ></a-input-number>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="activityContact" label="活动联系人">
                <a-input
                  v-model="form.activityContact"
                  placeholder="请输入活动联系人"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="activityTel" label="活动联系方式">
                <a-input
                  v-model="form.activityTel"
                  placeholder="请输入活动联系人"
                  style="width: 80%"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              活动图片
              <commonUpload
                :fileList="fileList"
                @handleChange="handleChange"
              ></commonUpload>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import moment from "moment";
import { form, rules, options } from "./form.js";
import {
	activityInsert,
	activityUpdate,
	activityInfo,
	organizerSelect,
} from "@/api/operation/activity";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "新增活动",
			form,
			rules,
			options,
			fileList: [],
		};
	},
	mounted() {
		this.getOrganizer();
	},
	methods: {
		async getOrganizer() {
			let res = await organizerSelect();
			this.options.organizer = res.data;
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.fileList = [];
			this.form.imgUrls = [];
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editId === null) {
						let res = await activityInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await activityUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
		// handleChange(imgDataList) {
    //   this.fileList = imgDataList
		// 	let arr = []
		// 	imgDataList.forEach(ele => {
		// 		arr.push(ele.response.data)
		// 	})
		// 	this.form.imgUrls = arr
		// },
    handleChange(data) {
			this.fileList = data;
			this.form.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgUrls.push(ele.response.data)
				}
			})
		},
    // 报名截止时间小于活动截止时间
    disabledDate(value) {
        let hdStartValue = moment(this.form.activityEndTime);
        if (!value || !hdStartValue) {
          return false;
        }
        return hdStartValue < value;
    },
    // 活动截止时间大于报名截止时间
    disabledDate2(value) {
        let bmStartValue = moment(this.form.registrationEndTime);
        if (!value || !bmStartValue) {
          return false;
        }
        return bmStartValue >= value;
    }
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.title = "修改活动";
					this.form.id = val;
					activityInfo({ activityId: val }).then((res) => {
						this.form = res.data;
            this.form.imgUrls = [];
						if (res.data.imgList.length > 0) {
							const pic = [];
              this.form.imgUrls.push(res.data.imgList[0].url)
							for (let item of res.data.imgList) {
								let obj = {
									name: item.url.split("_")[0] + "." + item.url.split(".")[1],
									url: this.$ImgUrl(item.url),
									uid: item.url.split("_")[1],
									status: "done",
									thumbUrl: this.$ImgUrl(item.url),
								};
								pic.push(obj);
							}
							this.fileList = pic;
						} else {
							this.form.imgUrls = [];
						}
					});
				} else {
					this.title = "新增活动";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
